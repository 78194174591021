import Vue from 'vue';
import Vuex from 'vuex';
import {getUserInfo, logout} from '@/api/user';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: null,
        meta: {
            friendLink: [],
            title: '',
            model: '',
            themeColor: '',
            footerInfo: '',
            loginPic: '',
            logo: '',
            bgPic: '',
            enum: {},
            areaList: []
        },
        agent: window.location.hostname.split('.')[0]
    },
    mutations: {
        setUserInfo(state, info) {
            state.userInfo = info;
        },
        setMeta(state, info) {
            state.meta = info;
        },
        changeThemeColor(state, color) {
            state.meta.themeColor = color;
        }
    },
    getters: {
        hasLogin: state => state.userInfo !== null
    },
    actions: {
        async handleLogout({commit}) {
            await logout();
            commit('setUserInfo', null);
            sessionStorage.clear();
        },
        async getUserInfo({commit}) {
            const { data } = await getUserInfo();
            commit('setUserInfo', data);
            if (data === null) {
                sessionStorage.clear();
            } else if (data.bindWx) {
                sessionStorage.setItem('close_qrcode', '1');
            }
        }
    },
    modules: {
    }
});
