import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import '@/styles/css.css';
import '@/styles/public.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/common.css';
import '@/styles/page.css';
import '@/styles/css/layui.css';
import '@/assets/icons/iconfont.css';
import router from '@/router';
import store from '@/store';
import config from '@/config';
import VueCropper from 'vue-cropper';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueCropper);

/**
 * @description 全局注册应用配置
 */
Vue.prototype.$config = config;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
