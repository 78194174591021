<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>

export default {
    name: 'App',
    computed: {
        themeColor() {
            return this.$store.state.meta.themeColor;
        }
    },
    watch: {
        themeColor(val) {
            document.getElementById('app').style.setProperty('--themeColor', val);
        }
    }
};
</script>

<style lang="less">
#app {
    //--themeColor: #009688;
}
</style>
