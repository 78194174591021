import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routers';
import config from '../config';
import store from '@/store';
import {isMobileDevice} from '@/utils/validator';

Vue.use(VueRouter);

// 解决报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject)
{
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject)
{
    if (onResolve || onReject) {
        return originalReplace.call(this, location, onResolve, onReject);
    }
    return originalReplace.call(this, location).catch(err => err);
};

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    let nextObj = {};

    // 盐业公司单独处理
    if (to.name === 'yanye') {
        let url = 'https://click.goodjobs.cn/2023/yany/index.html';
        if (isMobileDevice()) {
            url = 'https://click.goodjobs.cn/2023/cpb/yany/index.html?v=2';
        }
        window.location.href = url;
        return;
    }
    // 广德市国有资产投资经营有限公司
    if (to.name === 'guangde') {
        let url = 'https://click.goodjobs.cn/2023/guangde/index.html';
        if (isMobileDevice()) {
            url = 'https://click.goodjobs.cn/2023/cpb/guangde/index.html';
        }
        window.location.href = url;
        return;
    }

    // 跳转到移动端
    if (isMobileDevice()) {
        window.location.href = window.location.origin + '/wap' + (to.name === 'home' ? '/notice' + window.location.search : to.fullPath);
        return;
    }

    if (config.wcAgents.includes(store.state.agent)) {
        switch (to.name) {
            case 'home':
                nextObj = { name: 'notice' };
                break;
            // case 'user-set':
            //     nextObj = { name: 'error_404' };
            //     break;
            default:
                break;
        }
    }
    if (to.name === 'my') {
        nextObj = { name: 'apply' };
    }

    // 链接中带origin跳到其他路由也加上
    if (to.query.origin || to.query.reg_origin || to.query.search) {
        next(nextObj);
        return;
    }
    if (
        ['goodjobs', 'community'].includes(from.query.origin)
        || ['goodjobs', 'community'].includes(from.query.reg_origin)
        || from.query.search
    ) {
        if (Object.keys(nextObj).length === 0) {
            nextObj = { path: to.path };
        }
        let tempQuery = {};
        if (from.query.origin) {
            tempQuery = {origin: from.query.origin};
        }
        if (from.query.reg_origin) {
            tempQuery.reg_origin = from.query.reg_origin;
        }
        if (from.query.search) {
            tempQuery.reg_origin = 'goodjobs';
        }
        nextObj.query = Object.assign({}, to.query, tempQuery);
    }

    next(nextObj);
});

export default router;
